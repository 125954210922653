// import { Login } from '../signin/sign-in';
import { useContext } from 'react';
import { logout } from '../../firebase';
import './header.scss';
import { AuthContext } from '../../auth-context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { urlFor } from '../../client/sanity-client';
import ubcmall_logo from '../../img/ubcmall_logo.png';

const Header = () => {
    const authContext = useContext(AuthContext);
    const user = authContext.user;
    const navigate = useNavigate();
    const location = useLocation()

    const loginOrRegister = location.pathname == "/login" || location.pathname == "/register";

    const login = () => {
        navigate("/login");
    }
    return (
        <div className="app-header">
            <div className='app-logo'>
                <img height={100} width={100}
                    // src='https://static.vecteezy.com/system/resources/previews/010/355/749/original/shopping-logo-online-shopping-store-mall-e-commerce-business-company-logo-illustration-free-vector.jpg' />
                    src={ubcmall_logo} />
                <span>UBC Mall</span>
            </div>

            {!loginOrRegister && <div>
                {user.id.length == 0 &&
                    <div className='landing-tabs'>

                        <Link to={`home`}>Home</Link>
                        <Link to={`events`}>Events</Link>
                        <Link to={`merchants`}>Merchants</Link>
                        <Link to={`offers`}>Offers</Link>

                        <div className='user-profile' >
                            <button onClick={login}>Login</button>
                        </div>
                    </div>
                }

                {user.id.length > 0 &&
                    <div className='user-profile' >
                        <div className='left-sidebar-header-name'>{user.name}</div>
                        {authContext.user.image && <img src={urlFor(authContext.user.image!).url()} height={50} width={50} />}

                        {/* <button onClick={loggingout}>Log out</button> */}
                    </div>
                }</div>}
        </div>
    )
}

export default Header;