import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { registerWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import "./register.scss";
import googleIcon from '../../img/google.png';

function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const register = () => {
        if (!name || !email)  alert("Please enter name and email");
        registerWithEmailAndPassword(name, email, mobile, password);
    };
    useEffect(() => {
        if (loading) return;
        // if (user) navigate("/events");
    }, [user, loading]);
    return (
        <div className="register">
            <h3>Join UBC community</h3>
            <div className="register__container">
                <input
                    type="text"
                    className="register__textBox"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                />
                <input
                    type="text"
                    className="register__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                 <input
                    type="text"
                    className="register__textBox"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="Phone/Mobile"
                />
                <input
                    type="password"
                    className="register__textBox"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button className="register__btn" onClick={register}>
                    Register
                </button>
                <button
                    className="register__btn register__google"
                    onClick={signInWithGoogle}
                >
                    <img src={googleIcon} className="google-icon"/> Register with Google
                </button>
                <div>
                    Already have an account? <Link to="/login">Login</Link> now.
                </div>
            </div>
        </div>
    );
}
export default Register;