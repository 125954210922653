import { ChangeEvent, useContext, useEffect, useState } from 'react';
import './my-rewards.scss';
import { AuthContext, User } from '../../auth-context';
import { client, urlFor } from '../../client/sanity-client';
import Modal from 'react-modal/';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { customModalStyles } from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCancel } from '@fortawesome/free-solid-svg-icons';

import { fetch_ClientHistory_ById } from '../../client/query';

export const MyRewards = () => {
  const authContext = useContext(AuthContext);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>();
  const [isScanQRDialogOpen, setScanQRDialogOpen] = useState<boolean>();

  const [updatedUser, setUpdatedUser] = useState<User>(authContext.user);
  const [userImage, setUserImage] = useState<File>();

  //
  const [ScanHistory, setScanHistory] = useState<any[]>([]);
  useEffect(() => {
    console.log(authContext.user.id);
    const userId = authContext.user.id;
    async function fetchClientHistory(userId: string) {
      try {
        const response = await client.fetch(fetch_ClientHistory_ById(userId));
        console.log(response);
        setScanHistory(response);
      } catch (error) {
        alert(error);
      }
    }
    fetchClientHistory(userId);
  }, []);
  //

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({
      ...prevUser!,
      [name]: value,
    }));
  };

  const [isScanningQr, setIsScanningQr] = useState(false);

  const navigate = useNavigate();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setUserImage(file!);
  };
  const previewStyle = {
    height: 600,
    width: 500,
    display: 'flex',
    justifycontent: 'center',
  };

  const saveUserProfile = async () => {
    const { name, email, mobile } = updatedUser!;

    // Validate all required fields
    if (!name || !email || !mobile) {
      alert('Please fill in all required fields.');
      return;
    }

    let imageUploaded;
    if (userImage) {
      imageUploaded = await client.assets.upload('image', userImage, {
        contentType: userImage!.type,
        filename: userImage!.name,
      });
    }
    debugger;
    const newUser = {
      userName: name,
      mobile: mobile,
      email: email,
      image: imageUploaded
        ? {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageUploaded._id,
          },
        }
        : authContext.user.image,
    };

    const response = await client
      .patch(authContext.user.id)
      .set(newUser)
      .commit();

    if (response._id) {
      authContext.setUser({
        image: newUser.image,
        mobile: newUser.mobile,
        name: newUser.userName,
        email: newUser.email,
        id: updatedUser.id,
        role: updatedUser.role,
      });
      setCreateDialogOpen(false);
      alert('Profile Updated.');
    }
  };
  return (
    <div className='myubc-container'>
      <div className='myubc-profile'>
        <div className='myubc-container-title'>My Profile</div>
        <div>Name: {authContext.user.name}</div>
        <div>Email: {authContext.user.email}</div>
        <div>Mobile: {authContext.user.mobile}</div>
        <div>Admin: {authContext.user.role == 'admin' ? 'Yes' : 'No'}</div>
        <button
          className='update-profile'
          onClick={() => {
            setCreateDialogOpen(true);
          }}>
          Update Profile
        </button>
      </div>
      {!isScanningQr && (
        <div className='card-container'>
          <div className='card'>
            <div className='header'>
              <h4>UBC Mall Membership Card</h4>
            </div>
            <div className='content'>
              <p>{authContext.user.name}</p>
              <p>Membership ID: {authContext.user.id}</p>
            </div>
          </div>

          <div
            className='scan-qr-btn'
            onClick={() => {
              setIsScanningQr(true);
            }}>
            <FontAwesomeIcon
              icon={faCamera}
              size='3x'
              className='scan-qr-icon'
            />
            Scan QR
          </div>
        </div>
      )}

      <div
        className='scanHistory'>
        <div className='myubc-container-title'>Merchants Scanning History</div>

        <div className='scanHistory-f'>
          <div className="h-i-time">
            <span>Time</span>

            {ScanHistory.map((r) => (
              <div key={r._id} >
                <div className='h-i-t'>{new Date(r._createdAt).toLocaleDateString()} {new Date(r._createdAt).toLocaleTimeString()}</div>
              </div>
            ))}
          </div>

          <div className="h-i-name">
            <span>Merchant Name</span>

            {ScanHistory.map((r) => (
              <div key={r._id} >
                <div className='h-i-n'>{r.merchantName}</div>
              </div>
            ))}
          </div>

        </div>
      </div>

      {isScanningQr && (
        <div className='scanner'>
          <QrScanner
            onDecode={(result) => {
              setIsScanningQr(false);
              navigate('/redeem-offer/' + result);
            }}
            onError={(error) => console.log(error?.message)}
          // videoStyle={previewStyle}
          />
        </div>
      )}

      {isScanningQr && (
        <button
          className='cancel-btn'
          onClick={() => {
            setIsScanningQr(false);
          }}>
          Cancel Scan
        </button>
      )}
      {isCreateDialogOpen && (
        <Modal
          style={customModalStyles}
          appElement={document.getElementById('root') as HTMLElement}
          isOpen={isCreateDialogOpen}
          overlayClassName={'update-profile-modal'}
          onRequestClose={() => {
            setCreateDialogOpen(false);
          }}>
          <h2>Update Profile</h2>
          <label className='modal-input-container'>
            Name:
            <input
              type='text'
              name='name'
              value={updatedUser.name}
              onChange={handleInputChange}
            />
          </label>

          <label className='modal-input-container'>
            Address:
            <input
              type='text'
              name='email'
              value={updatedUser.email}
              onChange={handleInputChange}
            />
          </label>

          <label className='modal-input-container'>
            Mobile:
            <input
              type='text'
              name='mobile'
              value={updatedUser.mobile}
              onChange={handleInputChange}
            />
          </label>

          <br />

          <label>
            Avatar:
            <p>
              <img
                src={
                  authContext.user.image
                    ? urlFor(authContext.user.image).url()
                    : 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'
                }
                height={50}
                width={50}
              />
            </p>
            <input
              type='file'
              onChange={handleImageChange}
            />
          </label>
          <br />
          <br />

          <div>
            <button
              onClick={() => {
                setCreateDialogOpen(false);
              }}>
              Cancel
            </button>
            <button onClick={saveUserProfile}>Save</button>
          </div>
        </Modal>
      )}
    </div>
  );
};
