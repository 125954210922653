import React, { useContext, useState } from "react";
import "./left-sidebar.scss";
import { faHome, faShoppingCart, faShop, faTags, faExpand, faMinus, faPlusCircle, faMinusCircle, faPlusSquare, faMinusSquare, faIdCard, faCreditCard, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth-context";
import { auth, logout } from "../../firebase";
import { urlFor } from "../../client/sanity-client";

enum SelectedTab {
  events,
  merchants,
  offers,
  myrewards
}


function LeftSidebar() {
  // const [collapsed, setCollapsed] = useState(false);
  const authContext = useContext(AuthContext);
  const name = authContext.user.name;
  const [selectedTab, setSelectedTab] = useState(SelectedTab.events);
  const navigate = useNavigate();

  // function toggleSidebar() {
  //   setCollapsed(!collapsed);
  // }
  // function handleScreenSizeChange(mq: MediaQueryList) {
  //   if (mq.matches) {
  //     setCollapsed(true);
  //   } else {
  //     setCollapsed(false);
  //   }
  // }

  // const mediaQuery = window.matchMedia("(max-width: 740px)");

  // mediaQuery.addEventListener("change", (event) => handleScreenSizeChange(event.currentTarget as MediaQueryList));

  const loggingout = () => {
    authContext.setUser({
      id: '',
      name: '',
      email: '',
      image: '',
      role: '',
      mobile: ''
    })
    logout()
    navigate('/')

  }
  return (
    <div className={`left-sidebar`}>
      {/* <div className="left-sidebar-header" onClick={toggleSidebar}>

        {!collapsed && <img src={authContext.user.image ? urlFor(authContext.user.image).url() : 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'} height={50} width={50} />}
        {!collapsed && <div className="left-sidebar-header-name">
          {name} {authContext.user.role == 'admin' ? '(admin)' : ''}</div>}
        <button>{collapsed ? <FontAwesomeIcon icon={faPlusSquare} /> : <FontAwesomeIcon icon={faMinusSquare} />}</button>
      </div> */}
      <div className="left-sidebar-content">
        <ul className="option-list">
          <Link to={`events`}> <li onClick={() => { setSelectedTab(SelectedTab.events) }} className={`${selectedTab == SelectedTab.events ? "seletedTab" : ""}`}><FontAwesomeIcon icon={faHome} size="1x" style={{ width: '1.25rem' }} /><span>Events</span></li></Link>
          <Link to={`merchants`}><li onClick={() => { setSelectedTab(SelectedTab.merchants) }} className={`${selectedTab == SelectedTab.merchants ? "seletedTab" : ""}`}><FontAwesomeIcon icon={faShop} size="1x" style={{ width: '1.25rem' }} /><span>Merchants</span></li></Link>
          <Link to={`offers`}><li onClick={() => { setSelectedTab(SelectedTab.offers) }} className={`${selectedTab == SelectedTab.offers ? "seletedTab" : ""}`}><FontAwesomeIcon icon={faTags} size="1x" style={{ width: '1.25rem' }} /><span>Offers</span></li></Link>
          <Link to={`my-rewards`}><li onClick={() => { setSelectedTab(SelectedTab.myrewards) }} className={`${selectedTab == SelectedTab.myrewards ? "seletedTab" : ""}`}><FontAwesomeIcon icon={faCreditCard} size="1x" style={{ width: '1.25rem' }} /><span>My UBC</span></li></Link>
          <li onClick={() => {loggingout()}}><FontAwesomeIcon icon={faSignOut} size="1x" style={{ width: '1.2rem' }} /><span>Logout</span></li>
        </ul>
      </div>
    </div>
  );
}

export default LeftSidebar;