import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import merchantReducer from '../features/merchant/merchant.slice';
import eventReducer from '../features/event-list/event-list.slice';
import merchantDetailReducer from '../features/merchant-detail/merchant-detail.slice';
import offersListReducer from '../features/offers/offers-list.slice';

export const store = configureStore({
  reducer: {
    event: eventReducer,
    merchant:merchantReducer,
    merchantDetail: merchantDetailReducer,
    offer:offersListReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
