export const userQuery = (userId: string) => {
    const query = `*[_type == "user" && _id == '${userId}']`;
    return query;
}

export const eventQuery = () => {
    const query = `*[_type == "event"]{
        title,
        content,
        image,
        userId,
        userName,
        _createdAt,
        _id,
        videoRef} | order(_createdAt desc)`;
    return query;
}

export const merchantsByCatalogQuery = (catalog: string) => {
    const query = `*[_type == "merchant" && catalog == '${catalog}' && approved == ${true}]`;
    return query;
}

export const merchantByIdQuery = (merchantId: string) => {
    return `*[_type == "merchant" && _id == '${merchantId}']`;
}

export const offersListQuery = () => {
    return `*[_type == "offer"]`;
}

export const lastestOffersListQuery = () => {
    return `*[_type == "offer"] | order(_updatedAt desc)[0...10]`;
}

export const fetchOffersByMerchant = (merchantId: string) => {
    return `*[_type == "offer" && merchantId == '${merchantId}']`;
}

export const fetchMyMerchantsQuery = (userId: string) => {
    const query = `*[_type == "merchant" && userId == '${userId}']`;
    return query;
}
// merchant: client name, creaetedat(time)
// y-m-d-hour-min
export const fetch_MerchantHistory_ById = (merchantId: string) => {
    const query = `*[_type == "redeem" && merchantId == '${merchantId}']`;
    return query;
}
// client: merchant name, creaetedat(time)
export const fetch_ClientHistory_ById = (userId: string) => {
    const query = `*[_type == "redeem" && userId == '${userId}']`;
    return query;
}

export const fetchAllCatalogs = () => {
    const query = `*[_type == "catalog"]`;
    return query;
}