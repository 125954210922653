import  { createClient }  from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url"; 
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const client = createClient({
    // projectId:process.env.REACT_APP_SANITY_PROJECT_ID,
    projectId :'4q4cinfb',
    dataset:'production',
    apiVersion:'2021-11-16',
    useCdn:false,
    // token:process.env.REACT_APP_SANITY_TOKEN
    token:'skQc0llKO6RESyj4v5fuNBgVsJhUJJpQIBu7e3YHVqA3vYryBjmZSw01qonUXQ5FalJYJU8WmJFWHrJIZb6H698eubZ0lfNQoEtgTmsrUzUdFyRXzCmuAalxmgF4fC1uhHjD2pd0PwlNmQMm5XEWrSizJHJl7vb5qzpiRV2MsyQL2gDnxiFo'
})

const builder = imageUrlBuilder(client);

export const urlFor = (source:SanityImageSource)=> builder.image(source);