import { Link } from 'react-router-dom';
import { OfferItem } from '../../types/offer-item';
import './right-sidebar.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchAllOffers, fetchLastestOffers, selectLatestOffers, selectOffers } from '../../features/offers/offers-list.slice';
import { useEffect } from 'react';
import { urlFor } from '../../client/sanity-client';

const RightSideBar = () => {

  const latestOffers = useAppSelector(selectLatestOffers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLastestOffers());
  }, [dispatch]);
  return (
    <div className='right-bar'>
      <h5>Latest Offers</h5>
      <div className='latest-offers-list'>
        {latestOffers.map((o: OfferItem) => (
          <div className='lastest-offer-item'>
            <Link to={`/merchant-detail/${o.merchantId}`}><img src={urlFor(o.image!).url()}></img></Link>
            <span className='lastest-offer-item-title'>{o.title}</span>
            <span>{o.merchantName}</span>
            {/* <span>{o.description}</span> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RightSideBar;