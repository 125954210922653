import { Link } from "react-router-dom";
import { MerchantCatalog } from "../../types/merchat-catalog";
import "./merchant-catalog-list.scss";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { MerchantItem } from "../../types/merchant-item";
import Modal from "react-modal/";
import { client, urlFor } from "../../client/sanity-client";
import { AuthContext } from "../../auth-context";
import { fetchAllCatalogs, fetchMyMerchantsQuery } from "../../client/query";
import { useAppSelector } from "../../store/hooks";
import { selectLoadingState } from "./merchant.slice";
import { LoadingIndicator } from "../../components/loader/loader";
import { customModalStyles } from "../../styles";

enum MerchantMainPageSelect {
  catalog,
  my,
  manageCatalog
}

export const MerchantCatalogList = () => {
  // const MerchantCatalogs: MerchantCatalog[] = [
  //   { title: "F&B", value: "f&b", image: "https://assets.entrepreneur.com/content/3x2/2000/1621186170-shutterstock-1690711429.jpeg?format=pjeg&auto=webp&crop=16:9&width=675&height=380" },
  //   { title: "Financial", value: "financial", image: "https://st2.depositphotos.com/1258191/7220/i/450/depositphotos_72200647-stock-photo-financial-services-professional-team.jpg" },
  //   { title: "Badminton", value: "badminton", image: "https://www.nydhi.com/cdn/shop/products/TKFCE_5_420x420_crop_center.jpg?v=1625758019" },
  //   { title: "Travel", value: "travel", image: "https://welpmagazine.com/wp-content/uploads/2020/09/7.jpg" },
  //   { title: "Wholesale", value: "wholesale", image: "https://www.poundwholesale.co.uk/media/wysiwyg/what-is-wholesale-img_1.jpg" },
  //   { title: "Investment", value: "investment", image: "https://media.gq-magazine.co.uk/photos/5e25d00550c26e0008a9b030/16:9/pass/20200120-invest.jpg" },
  // ];

  const [MerchantCatalogs, SetMerchantCatalogs] = useState<MerchantCatalog[]>();

  const [merchant, setMerchant] = useState<MerchantItem>({
    merchantName: "",
    address: "",
    contact: "",
    profile: "",
    catalog: "",
    image: null,
    offers: null,
    _id: null,
    userId: '',
    approved: false
  });

  const authContext = useContext(AuthContext)

  const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>();

  const [merchantImage, setMerchantImage] = useState<File>();
  const [selectedPage, setSelectedPage] = useState<MerchantMainPageSelect>(MerchantMainPageSelect.catalog);
  const [myMerchants, setMyMerchants] = useState<MerchantItem[]>();
  const loadingState = useAppSelector(selectLoadingState);




  useEffect(() => {
    fetchCatalogs();
  }, [])
  const fetchMyMerchants = async () => {
    const merchants = await client.fetch(fetchMyMerchantsQuery(authContext.user.id));
    setMyMerchants(merchants);
  }
  const fetchCatalogs = async () => {
    const catalogs = await client.fetch(fetchAllCatalogs());
    SetMerchantCatalogs(catalogs);
  }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMerchant((prevMerchant) => ({
      ...prevMerchant,
      [name]: value,
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setMerchantImage(file!);
  };

  const handleCatalogChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setMerchant((prevMerchant) => ({
      ...prevMerchant,
      catalog: value,
    }));
  };

  const saveMerchant = async () => {
    const { merchantName, address, contact, profile, catalog } =
      merchant;

    // Validate all required fields
    if (
      !merchantName ||
      !address ||
      !contact ||
      !profile ||
      !catalog ||
      !merchantImage
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    const imageUploaded = await client.assets.upload("image", merchantImage, {
      contentType: merchantImage.type,
      filename: merchantImage.name,
    });

    if (imageUploaded._id) {
      const response = await client.create({
        _type: "merchant",
        merchantName: merchant.merchantName,
        address: merchant.address,
        contact: merchant.contact,
        profile: merchant.profile,
        catalog: merchant.catalog,
        userId: authContext.user.id,
        image: {
          _type: "image",
          asset: {
            _type: "reference",
            _ref: imageUploaded._id,
          },
        },
      });

      if (response._id) {
        setCreateDialogOpen(false);
        alert(
          "Merchant Registered, UBC will review your request and notify you once it is approved."
        );
      }
    }

    else {
      alert('image upload failed, please try another image, valid image formats are png, svg, jpeg, gif, tiff');
    }
  };

  return (
    <div className="merchant-catalog-container">
      {/* <img className="merchants-banner" src="https://fragrant.mobiletransaction.org/wp-content/uploads/2022/12/merchant-fees-australia.jpg.webp" /> */}

      <div className="merchant-catalog-list-title">

        <div className={`tab ${selectedPage == MerchantMainPageSelect.catalog ? 'active-tab' : ''}`} onClick={() => { setSelectedPage(MerchantMainPageSelect.catalog) }}>Merchants</div >
        {authContext?.user?.id?.length > 0 && <div className={`tab ${selectedPage == MerchantMainPageSelect.my ? 'active-tab' : ''}`} onClick={() => { setSelectedPage(MerchantMainPageSelect.my); fetchMyMerchants() }}>My merchants</div >}

        {authContext?.user?.id?.length > 0 && authContext.user.role == 'admin' && <div className={`tab ${selectedPage == MerchantMainPageSelect.manageCatalog ? 'active-tab' : ''}`} onClick={() => { setSelectedPage(MerchantMainPageSelect.manageCatalog); alert("Pleas go to Sanity Admin Console to manage Catalogs") }}>Manage Catalogs</div >}


      </div>
      {
        authContext?.user?.id?.length > 0 &&
        <button className="register-merchant"
          onClick={() => {
            setCreateDialogOpen(true);
          }}
        >
          Register Merchant
        </button>
      }
      {
        loadingState == 'loading' && <LoadingIndicator />
      }


      {loadingState == 'idle' && selectedPage == MerchantMainPageSelect.catalog && <div className="merchant-catalog-list">
        {MerchantCatalogs && MerchantCatalogs.map((mc: MerchantCatalog) => (
          <div className="merchant-catalog-group">
            <Link to={`/merchants/${mc.catalogName}`}>
              <img src={urlFor(mc.image!).url()} ></img>
            </Link>
            <span>{mc.catalogName}</span>
          </div>
        ))}
      </div>}

      {loadingState == 'idle' && selectedPage == MerchantMainPageSelect.my && <div className="merchant-my-list">
        {myMerchants && (
          <div className="merchant-my-list-content">
            {myMerchants.map((m: MerchantItem) => {
              return (
                <Link className="merchant-item-my" state={{ from: 'merchants' }} to={`/merchant-detail/${m._id}`}>

                  <img src={urlFor(m.image!).url()}></img>
                  <h5>{m.merchantName}</h5>
                </Link>
              );
            })}
          </div>
        )}
      </div>}

      {/* {loadingState == 'idle' && selectedPage == MerchantMainPageSelect.manageCatalog && <div className="merchant-catalog-list">
        {MerchantCatalogs && 
          
            MerchantCatalogs.map((m: MerchantCatalog) => {
              return (
                <div>

                  <img src={urlFor(m.image!).url()}></img>
                  <h5>{m.catalogName}</h5>
                </div>
              );
            }
     
        )}
      </div>} */}
      {isCreateDialogOpen && (
        <Modal
          style={customModalStyles}
          appElement={document.getElementById('root') as HTMLElement}
          isOpen={isCreateDialogOpen}
          overlayClassName={'create-merchant-modal'}
          onRequestClose={() => {
            setCreateDialogOpen(false);
          }}
        >
          <h4>Become UBC Mall Merchant</h4>
          <label className="modal-input-container">
            Merchant Name:
            <input
              type="text"
              name="merchantName"
              value={merchant.merchantName}
              onChange={handleInputChange}
            />
          </label>

          <label className="modal-input-container">
            Address:
            <input
              type="text"
              name="address"
              value={merchant.address}
              onChange={handleInputChange}
            />
          </label>

          <label className="modal-input-container">
            Contact:
            <input
              type="text"
              name="contact"
              value={merchant.contact}
              onChange={handleInputChange}
            />
          </label>

          <label className="modal-input-container">
            Profile:
            <input
              type="text"
              name="profile"
              value={merchant.profile}
              onChange={handleInputChange}
            />
          </label>
          <label className="modal-input-container">
            Catalog:
            <select style={{ marginLeft: '.5rem' }}
              name="catalog"
              value={merchant.catalog}
              onChange={handleCatalogChange}
            >
              <option value="">Select Catalog</option>
              {MerchantCatalogs && MerchantCatalogs.map((option) => (
                <option key={option.catalogName} value={option.catalogName}>
                  {option.catalogName}
                </option>
              ))}
            </select>
          </label>


          <label className="modal-input-container">
            Merchant Logo:
            <input type="file" onChange={handleImageChange} />
          </label>

          <div>
            <button
              onClick={() => {
                setCreateDialogOpen(false);
              }}
            >
              Cancel
            </button>
            <button onClick={saveMerchant}>Save</button>
          </div>
        </Modal>
      )}

    </div>
  );
};
