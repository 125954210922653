import {Audio, Vortex} from 'react-loader-spinner'

export const LoadingIndicator = () => {
  
    return ( <div
        style={{
          width: "100%",
         height: "100",
        display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
    >
       <Vortex  height="100" width="100" />
      </div>)

    
  };