import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventItem } from "../../types/event-item";
import { faCancel, faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import "./event-list.scss";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../auth-context";
import { client, urlFor } from "../../client/sanity-client";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchEvents, selectEvents } from "./event-list.slice";
import Modal from "react-modal/";
import { customModalStyles } from "../../styles";
import { storage } from "../../firebase";
import {
  ref,
  // uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import ReactPlayer from "react-player";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";

export const EventMain = () => {
  const events = useAppSelector(selectEvents);
  const dispatch = useAppDispatch();
  const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>();
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const [newEvent, setNewEvent] = useState<EventItem>({
    content: "",
    image: "",
    title: "",
    _createdAt: new Date(),
    _id: "",
    userId: user.id,
    userName: user.name,
    videoRef: "",
  });
  const [eventImage, setEventImage] = useState<File>();
  const [eventVideo, setEventVideo] = useState<File>();
  const [eventVideoPlaying, seteventVideoPlaying] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setEventImage(file!);
  };

  const handleVideoeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setEventVideo(file!);
  };

  const saveEvent = async () => {
    const { content, title } = newEvent;

    // Validate all required fields
    if (!content || !title) {
      alert("Please fill in all required fields.");
      return;
    }
    let imageUploaded;
    if (eventImage) {
      imageUploaded = await client.assets.upload("image", eventImage!, {
        contentType: eventImage!.type,
        filename: eventImage!.name,
      });
    }

    const response = await client.create({
      content: newEvent.content,
      title: newEvent.title,
      _createdAt: new Date(),
      _type: "event",
      userId: user.id,
      userName: user.name,
      image: imageUploaded?._id
        ? {
          _type: "image",
          asset: {
            _type: "reference",
            _ref: imageUploaded._id,
          },
        }
        : null,
    });

    if (response._id) {
      if (eventVideo) {
        await handleVideoUpload(response._id);
      }
      setCreateDialogOpen(false);
      alert("Event Created!");
      dispatch(fetchEvents());
    }
  };

  const handleVideoUpload = async (eventId: string) => {
    // const file = e.target.files && e.target.files[0];
    if (!eventVideo) {
      return;
    }

    const storageRef = ref(storage, `/posts/video/${eventId}`);
    const uploadTask = await uploadBytes(storageRef, eventVideo);

    if (uploadTask.ref) {
      const url = await getDownloadURL(uploadTask.ref);
      client.patch(eventId).set({ videoRef: url }).commit();
    }

    // uploadTask.on(
    //     "state_changed",
    //     (snapshot) => {
    //         const percent = Math.round(
    //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //         );
    //     },
    //     (err) => console.log(err),
    //     () => {
    //         // download url
    //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //             console.log(url);
    //         });
    //     }
    // );
  };

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);
  return (
    <div className="events-container">
      {/* <div className="banner">
        <img src="https://amaa.org/wp-content/uploads/2017/09/upcoming-events-banner-960x250.jpg" />
      </div> */}
      {user.role == "admin" && (
        <button
          className="new-event-btn"
          onClick={() => {
            setCreateDialogOpen(true);
          }}
        >
          Post New Event
        </button>
      )}
      {/* <h3 style={{marginLeft:"1rem", marginRight:"1rem", fontWeight:"400"}}>Lastest Activities and events brought to you by UBC</h3> */}
      <div className="events-list">
        {events &&
          events.map((e: EventItem) => (
            <div className="event-item">
              <div className="event-header">
                <div className="avatar">
                  {
                    <img
                      src={
                        user.image
                          ? urlFor(user.image).url()
                          : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
                      }
                      height={150}
                      width={100}
                      alt="Image"
                    ></img>
                  }{" "}
                </div>
                <div className="event-title">
                  <div className="author-name">{e.userName}</div>
                  <div>
                    Post date: {new Date(e._createdAt).toLocaleString([], { dateStyle: 'short', timeStyle: 'short', hour12: true })}
                  </div>
                </div>
              </div>
              <div className="event-item-title">{e.title}</div>
              <p>{e.content}</p>
              {e.image && (
                <div className="event-image">
                  <img src={urlFor(e.image).url()} height={400}></img>
                </div>
              )}
              {e.videoRef && (
                <div
                  className="event-video"
                  onMouseEnter={() => {
                    seteventVideoPlaying(true);
                  }}
                  onMouseLeave={() => {
                    seteventVideoPlaying(false);
                  }}
                >
                  <ReactPlayer
                    controls
                    width="100%"
                    height="100%"
                    className="react-player"
                    url={e.videoRef}
                    playing={eventVideoPlaying}

                  />
                </div>
              )}
            </div>
          ))}
      </div>
      {isCreateDialogOpen && (
        <Modal
          appElement={document.getElementById("root") as HTMLElement}
          isOpen={isCreateDialogOpen}
          overlayClassName={"create-event-modal"}
          onRequestClose={() => {
            setCreateDialogOpen(false);
          }}
          style={customModalStyles}
        >
          <h2>Create New Event</h2>
          <label className="modal-input-container">
            Event Title:
            <input
              type="text"
              name="title"
              value={newEvent.title}
              onChange={handleInputChange}
            />
          </label>

          <label className="modal-input-container">
            Content:
            <input
              type="text"
              name="content"
              value={newEvent.content}
              onChange={handleInputChange}
            />
          </label>

          <br />

          {eventVideo == null && (
            <div>
              <label className="modal-input-container">
                Image:
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}> <input type="file" onChange={handleImageChange} />
                  {
                    eventImage && <FontAwesomeIcon
                      onClick={() => {
                        setEventImage(undefined);
                      }}
                      icon={faCancel}
                    />
                  }
                </div>
              </label>
            </div>
          )}
          <br />

          {eventImage == null && (
            <div>
              <label className="modal-input-container">
                Video:
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                  <input type="file" onChange={handleVideoeChange} />
                  {
                    eventVideo && <FontAwesomeIcon
                      onClick={() => {
                        setEventVideo(undefined);
                      }}
                      icon={faCancel}
                    />
                  }
                </div>
              </label>
            </div>
          )}
          <br />

          <div>
            <button
              onClick={() => {
                setCreateDialogOpen(false);
              }}
            >
              Cancel
            </button>
            <button onClick={saveEvent}>Save</button>
          </div>
        </Modal>
      )}
    </div>
  );
};
