import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store'
import { EventItem } from '../../types/event-item';
import { client } from '../../client/sanity-client';
import { eventQuery, lastestOffersListQuery, offersListQuery } from '../../client/query';
import { OfferItem } from '../../types/offer-item';

export interface OffersState {
    offers: OfferItem[];
    lastestOffers:OfferItem[]
    status: 'idle' | 'loading' | 'failed';
    lastestOffersStatus:'idle' | 'loading' | 'failed';
}

const initialState: OffersState = {
    offers: [],
    lastestOffers:[],
    status: 'idle',
    lastestOffersStatus:'idle'
};

export const fetchAllOffers = createAsyncThunk(
    'event/fetchAllOffers',
    async () => {
        try {
            const response = await client.fetch(offersListQuery());
            // The value we return becomes the `fulfilled` action payload
            return response;
        } catch (error) {
            alert(error)
        }
    }
);

export const fetchLastestOffers = createAsyncThunk(
    'event/fetchLastestOffers',
    async () => {
        try {
            const response = await client.fetch(lastestOffersListQuery());
            // The value we return becomes the `fulfilled` action payload
            return response;
        } catch (error) {
            alert(error)
        }
    }
);

export const offersListSlice = createSlice({
    name: 'offers-list',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //   state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOffers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllOffers.fulfilled, (state, action) => {
                state.status = 'idle';
                state.offers = action.payload;
            })
            .addCase(fetchAllOffers.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchLastestOffers.pending, (state) => {
                state.lastestOffersStatus = 'loading';
            })
            .addCase(fetchLastestOffers.fulfilled, (state, action) => {
                state.lastestOffersStatus = 'idle';
                state.lastestOffers = action.payload;
            })
            .addCase(fetchLastestOffers.rejected, (state) => {
                state.lastestOffersStatus = 'failed';
            });
    },
});

// export const { fetchEvents } = eventSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOffers = (state: RootState) => state.offer.offers;
export const selectLatestOffers = (state: RootState) => state.offer.lastestOffers;
export const selectOffersState = (state: RootState) => state.offer.status;
export const selectLastestOffersState = (state: RootState) => state.offer.lastestOffersStatus;


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default offersListSlice.reducer;
