import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OfferItem } from '../../types/offer-item';
import './merchant-detail.scss';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchMerchantById,
  selectMerchantById,
  selectMerchantByIdState,
} from './merchant-detail.slice';
import { client, urlFor } from '../../client/sanity-client';
import { AuthContext } from '../../auth-context';
import Modal from 'react-modal/';
import { fetchOffersByMerchant } from '../../client/query';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { LoadingIndicator } from '../../components/loader/loader';
import { customModalStyles } from '../../styles';

import { fetch_MerchantHistory_ById } from '../../client/query';

const MerchantDetail = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const merchant = useAppSelector(selectMerchantById);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const host =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');

  const [ScanHistory, setScanHistory] = useState<any[]>([]);
  useEffect(() => {
    async function fetchMerchantHistory(Id: string) {
      try {
        const response = await client.fetch(fetch_MerchantHistory_ById(Id));
        console.log(response);
        setScanHistory(response);
      } catch (error) {
        alert(error);
      }
    }
    if (merchantId !== undefined) {
      fetchMerchantHistory(merchantId);
    }
  }, []);

  const handleGoBack = () => {
    const state = location.state;
    if (state.from == 'offers') {
      navigate('/offers');
    } else {
      navigate('/merchants');
    }
  };

  const [newOffer, setNewOffer] = useState<OfferItem>({
    merchantName: '',
    title: '',
    description: '',
    image: null,
    merchantId: '',
    _id: '',
  });

  const [offerImage, setOfferImage] = useState<File>();

  const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  const loadingState = useAppSelector(selectMerchantByIdState);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewOffer((prevOffer) => ({
      ...prevOffer,
      [name]: value,
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setOfferImage(file!);
  };

  const saveOffer = async () => {
    const { title, description } = newOffer;

    // Validate all required fields
    if (!title || !description || !offerImage) {
      alert('Please fill in all required fields.');
      return;
    }

    const imageUploaded = await client.assets.upload('image', offerImage, {
      contentType: offerImage.type,
      filename: offerImage.name,
    });

    if (imageUploaded._id) {
      const response = await client.create({
        _type: 'offer',
        merchantName: merchant.merchantName,
        title: newOffer.title,
        description: newOffer.description,
        merchantId: merchant._id,
        image: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageUploaded._id,
          },
        },
      });

      if (response._id) {
        setCreateDialogOpen(false);
        alert('Offer Created');
      }

      dispatch(fetchMerchantById(merchantId ?? ''));
    } else {
      alert(
        'image upload failed, please try another image, valid image formats are png, svg, jpeg, gif, tiff'
      );
    }
  };

  const authContext = useContext(AuthContext);

  useEffect(() => {
    dispatch(fetchMerchantById(merchantId ?? ''));
  }, [dispatch]);

  const handleRemoveOffer = (id: string) => {
    client.delete(id).then((v) => {
      if (v) {
        dispatch(fetchMerchantById(merchantId ?? ''));
      }
    });
  };

  return (
    <div className='merchant-container'>
      <button
        className='back'
        onClick={handleGoBack}>
        Back
      </button>
      <br />
      {loadingState == 'loading' && <LoadingIndicator />}

      {loadingState == 'idle' && (
        <div className='merchant-info'>
          <div className='merchant-name'>Merchant: {merchant.merchantName}</div>
          <img
            src={
              merchant.image ? urlFor(merchant.image!).url() : 'http://123.com'
            }></img>
          <div>Address: {merchant.address}</div>
          <div>Contact: {merchant.contact}</div>
          <div>Profile: {merchant.profile}</div>
          <div>Status: {merchant.approved ? 'Active' : 'Waiting Approval'}</div>
          <div className='qr'>
            QR:
            <br />
            <br />
            {merchant.approved ? '' : 'Not Available (Pending Approval)'}
            {merchant.approved && (
              <div
                style={{
                  background: 'white',
                  padding: '16px',
                  height: '250px',
                  width: '250px',
                }}>
                <QRCode value={`${merchant._id}`} />
              </div>
            )}
          </div>
          {authContext.user.id == merchant.userId && (
            <div>
              {/* <button
                className="edit"
                onClick={() => {
                  alert("Please contact Max for updating your merchant!");
                }}
              >
                Update Merchant
              </button> */}
              <button
                className='remove'
                onClick={() => {
                  alert('Please contact Max for updating your merchant!');
                }}>
                Remove Merchant
              </button>
            </div>
          )}
        </div>
      )}

      <div className='offers-title'>
        <h4>Current Offers</h4>
        {authContext.user.id == merchant.userId && (
          <button
            className='create-new-offer-btn'
            onClick={() => {
              if (!merchant.approved) {
                alert(
                  'Offer creation is not allowed, please wait for UBC admin to approve merchant registration.'
                );
              } else {
                setCreateDialogOpen(true);
              }
            }}>
            Create new offer
          </button>
        )}
      </div>
      <div className='merchant-offers-list'>
        {merchant.offers &&
          merchant.offers.map((o: OfferItem) => (
            <div
              className='merchant-offer-item'
              key={o._id}>
              <img src={urlFor(o.image!).url()} />

              <div className='offer-title'>
                <h5>{o.title}</h5>
                {authContext.user.id == merchant.userId && (
                  <button
                    onClick={() => {
                      handleRemoveOffer(o._id);
                    }}>
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
      <br />

      {
        (authContext?.user?.id?.length > 0 && ScanHistory.length > 0) &&
        <div className='merchantScanHistory'>
          <div className='myubc-container-title'>Clients Scanning History</div>

          <div className='scanHistory-f'>
            <div className="h-i-time">
              <span>Time</span>

              {ScanHistory.map((r) => (
                <div key={r._id} >
                  <div className='h-i-t'>{new Date(r._createdAt).toLocaleDateString()} {new Date(r._createdAt).toLocaleTimeString()}</div>
                </div>
              ))}
            </div>

            <div className="h-i-name">
              <span>Client Name</span>

              {ScanHistory.map((r) => (
                <div key={r._id} >
                  <div className='h-i-n'>{r.merchantName}</div>
                </div>
              ))}
            </div>

          </div>
        </div>
      }

      {isCreateDialogOpen && (
        <Modal
          style={customModalStyles}
          appElement={document.getElementById('root') as HTMLElement}
          overlayClassName={'create-offer-modal'}
          isOpen={isCreateDialogOpen}
          onRequestClose={() => {
            setCreateDialogOpen(false);
          }}>
          <label className='modal-input-container'>
            Title:
            <input
              type='text'
              name='title'
              value={newOffer.title}
              onChange={handleInputChange}
            />
          </label>

          <label className='modal-input-container'>
            description:
            <input
              type='text'
              name='description'
              value={newOffer.description}
              onChange={handleInputChange}
            />
          </label>

          <label className='modal-input-container'>
            Image:
            <input
              type='file'
              onChange={handleImageChange}
            />
          </label>

          <div>
            <button
              onClick={() => {
                setCreateDialogOpen(false);
              }}>
              Cancel
            </button>
            <button onClick={saveOffer}>Save</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MerchantDetail;
