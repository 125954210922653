import React, { useContext, useEffect, useState } from 'react';
import './App.scss';
import LeftSidebar from './components/left-sidebar/left-sidebar';
import MainContent from './components/main-content/main-content';
import RightSideBar from './components/right-sidebar/right-sidebar';
import Header from './components/header/header';
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { auth, logout } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { AuthContext } from './auth-context';
import { client } from './client/sanity-client';
import { userQuery } from './client/query';
import { Landing } from './components/landing/landing';
import Login from './components/signin/sign-in';
import Register from './components/register/register';
import Modal from 'react-modal';
import { EventMain } from './features/event-list/event-list';
import { MerchantCatalogList } from './features/merchant/merchant-catalog-list';
import { MerchantList } from './features/merchant-list/merchant-list';
import MerchantDetail from './features/merchant-detail/merchant-detail';
import { OffersList } from './features/offers/offers-list';
import Reset from './components/reset-pw/reset-password';
import { MyRewards } from './features/my-rewards/my-rewards';
import { RedeemOffer } from './features/redeem-offer/redeem-offer';



Modal.setAppElement('#root');

const App = () => {
  const [user, loading, error] = useAuthState(auth);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fetchUserName = async () => {
    try {
      if (user?.uid) {
        const u = await client.fetch(userQuery(user!.uid));
        const fetchedUser = u[0];
        authContext.setUser({ image: fetchedUser.image, mobile: fetchedUser.mobile, name: user.displayName!.toString(), email: user.email!, id: user.uid, role: fetchedUser?.admin == null ? "" : fetchedUser?.admin ? 'admin' : '' });
      }

    } catch (err) {
      logout();
      authContext.setUser({
        id: '',
        name: '',
        email: '',
        image: '',
        role: '',
        mobile: ''
      })
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/login");
    fetchUserName();
  }, [user, loading]);

  return (
    <div className="App">
        {/* <Routes>
                <Route path="/" element={<EventMain />} />
                <Route path="/events" element={<EventMain />} />
                <Route path="/merchants" element={<MerchantCatalogList />} />
                <Route path="/offers" element={<OffersList />} />
                <Route path="/merchants/:catalog" element={<MerchantList />} />
                <Route path="/merchant-detail/:merchantId" element={<MerchantDetail />} />
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/reset" element={<Reset/>}/>
                <Route path="/my-rewards" element={<MyRewards/>}/>
                <Route path="/redeem-offer/:merchantId" element={<RedeemOffer/>}/>

            </Routes> */}
      <Header />
      <div className='app-wrapper'>
        {!authContext.user.id && location.pathname != '/login' && location.pathname != '/register' && <Landing />}

        {!authContext.user.id && location.pathname == '/login' && <Login />}
        {!authContext.user.id && location.pathname == '/register' && <Register />}

        {authContext.user.id.length > 0 && <div className='app-container-main'>
          <LeftSidebar /><MainContent /><RightSideBar /></div>}


      </div>
    </div>
  );
}

export default App;
