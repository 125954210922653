import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { client } from "../../client/sanity-client";
import { merchantByIdQuery } from "../../client/query";
import "./redeem-offer.scss";
import { AuthContext } from "../../auth-context";

export const RedeemOffer = () => {
  const { merchantId } = useParams();
  const [merchantName, setMerchantName] = useState("");
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    client.fetch(merchantByIdQuery(merchantId as string)).then((v) => {
      setMerchantName(v[0].merchantName);
    });
  }, []);

  const redeem = async () => {
    const res = await client.create({
      merchantName: merchantName,
      merchantId: merchantId,
      _createdAt: new Date(),
      _type: "redeem",
      userId: authContext.user.id,
      userName: authContext.user.name,
    });

    if (res._id) {
      alert("Offer Redeen Successful!");
      setSuccess(true)
    }
  };

  const handleGoBack = () => {
    navigate("/my-rewards");
  };
  return (
    <div className="redeem-offer-container">
      <button onClick={handleGoBack} className="back">
        Back
      </button>
      {!isSuccess && <p>Please confirm you want to redeem the Offer from {merchantName}.</p>}

      {!isSuccess && <button className="confirm" onClick={redeem}>Confirm</button>}
    </div>
  );
};
