import {
  GoogleAuthProvider, getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
// import {
//   getFirestore,
//   query,
//   getDocs, collection,
//   where, addDoc,
// } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { client } from '../src/client/sanity-client'
import { getStorage } from "firebase/storage";
 

const firebaseConfig = {
  apiKey: "AIzaSyD30DyG_7AMoFzshYwfyrc6dd-YYjV29hk",
  authDomain: "ubc-mall.firebaseapp.com",
  projectId: "ubc-mall",
  storageBucket: "ubc-mall.appspot.com",
  messagingSenderId: "177692521141",
  appId: "1:177692521141:web:77946e19e1f309e85a44fc"
};

const app: any = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const storage = getStorage(app);

// const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const doc = {
      _id: user.uid,
      userName: user.displayName,
      _type: "user",
      email: user.email
    }

    await client.createIfNotExists(doc);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name: string, email: string, mobile:string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const doc = {
      _id: user.uid,
      userName: name,
      _type: "user",
      email: user.email,
      mobile: mobile
    }

    await client.createIfNotExists(doc);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);

};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

