import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import * as React from 'react';

export type User = {
    name: string,
    email: string,
    id: string,
    role:string,
    mobile:string,
    image: SanityImageSource | null
}

export interface UserContextInterface {
    user: User,
    setUser: React.Dispatch<React.SetStateAction<User>>
}

const defaultState = {
    user: {
        name: '',
        email: '',
        id: '',
        mobile:''
    },
    setUser: (user: User) => { }
} as UserContextInterface

export const AuthContext = React.createContext(defaultState);

type UserProviderProps = {
    children: React.ReactNode;
}

export default function AuthProvider({ children }: UserProviderProps) {
    const [user, setUser] = React.useState<User>({
        name: '',
        email: '',
        id: '',
        role:'',
        mobile:'',
        image:null

    });

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

