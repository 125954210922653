import './landing.scss';
import landbg from '../../img/landing-bg.jpg';
import land2 from '../../img/landing-second.jpg';
import land3 from '../../img/landing-third.jpg';
import land4 from '../../img/landing-fourth.jpg';
import acy from '../../img/acy.png';
import cjhh from '../../img/cjhh.png';
import wtgj from '../../img/wtgj.png';
import wbadmintonworxtgj from '../../img/badmintonworx.png';
import empire from '../../img/empire.png';
import maxautosyd from '../../img/maxautosyd.png';
import loyal from '../../img/loyal.png';
import mygym from '../../img/mygym.jpg';
import umee from '../../img/umee.png';
import threes from '../../img/threes.png';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import { BrowseAllMerchants } from '../browse-all-merchants/browse-all-merchants';
import { EventMain } from '../../features/event-list/event-list';
import { MerchantCatalogList } from '../../features/merchant/merchant-catalog-list';
import { OffersList } from '../../features/offers/offers-list';
import { MerchantList } from '../../features/merchant-list/merchant-list';
import MerchantDetail from '../../features/merchant-detail/merchant-detail';

export const Landing = () => {
    const navigate = useNavigate()


    // const [showAllMerchantCatalog, setShowAllMerchantCatalog] = useState(false);


    return (
        <Routes>

            <Route path="/" element={<LandingMain />} />
            <Route path="/home" element={<LandingMain />} />
            <Route path="/events" element={<EventMain />} />
            <Route path="/merchants" element={<MerchantCatalogList />} />
            <Route path="/offers" element={<OffersList />} />
            <Route path="/merchants/:catalog" element={<MerchantList />} />
            <Route path="/merchant-detail/:merchantId" element={<MerchantDetail />} />


        </Routes>



    )
}

const LandingMain = () => {

    const navigate = useNavigate()
    return <div className='landing-container'>
        {/* {showAllMerchantCatalog && <BrowseAllMerchants />} */}

        {/* {!showAllMerchantCatalog &&  */}
        <div>
            {/* <div className='all-merchants-btn'> <button onClick={() => {
            setShowAllMerchantCatalog(true)
        }}>Browse all UBC Mall merchants</button></div> */}
            <div className='landing-title'>
                <div className='landing-title-text'>
                    <div className='landing-title-text-main'>Discover the best deals from your favorite merchants with UBC Mall.</div>
                    <div className='landing-title-text-sub'>Register for free and unlock exclusive discounts from top merchants in your area.</div>
                    <div className='landing-register-btn-group'>
                        <button className='landing-register-btn' onClick={() => {
                            navigate('/register')
                        }}>Register Now</button>
                    </div>

                </div>
                <img src={landbg} height={500} width={700} />
            </div>

            <div className='landing-title'>
                <img src={land2} height={500} width={700} />
                <div className='landing-title-text'>
                    <div className='landing-title-text-main'>Find the best deals in one place.</div>
                    <div className='landing-title-text-sub'>UBC Mall brings together the best deals from your favorite merchants, saving you time and money.</div>
                </div>

            </div>

            <div className='landing-title'>

                <div className='landing-title-text'>
                    <div className='landing-title-text-main'>Discover new merchants.</div>
                    <div className='landing-title-text-sub'>With UBC Mall, you can explore new merchants in your area that you may have never discovered otherwise.</div>
                </div>
                <img src={land3} height={500} width={700} />
            </div>

            <div className='landing-title'>
                <img src={land4} height={500} width={700} />
                <div className='landing-title-text'>
                    <div className='landing-title-text-main'>Exclusive membership perks.</div>
                    <div className='landing-title-text-sub'>Sign up for our membership program to get access to additional perks, such as early access to deals and exclusive offers.</div>
                </div>

            </div>

            <div className='merchant-brands'>
                <h2>UBC Mall Merchants and still growing</h2>
                <div className='brand-imgs'>
                    <img src={acy} />
                    <img src={wtgj} />
                    <img src={cjhh} />
                    <img src={wbadmintonworxtgj} />
                    <img src={empire} />
                    <img src={maxautosyd} />
                    <img src={loyal} />
                    <img src={mygym} />
                    <img src={umee} />
                    <img src={threes} />
                </div>
                <button className='landing-register-btn landing-register-merchant-btn' onClick={() => {
                    navigate('/register')
                }}>Become UBC Mall Merchant</button>
            </div>
        </div>
        {/* } */}


    </div>
}