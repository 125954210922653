import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './main-content.scss';
import { faImage, faVideo } from '@fortawesome/free-solid-svg-icons';
import { EventItem } from '../../types/event-item';
import { EventMain } from '../../features/event-list/event-list';
import { Routes, Route } from 'react-router-dom';
import { MerchantCatalogList } from '../../features/merchant/merchant-catalog-list';
import { MerchantList } from '../../features/merchant-list/merchant-list';
import { OffersList } from '../../features/offers/offers-list';
import  Login from '../signin/sign-in';
import Register from '../register/register';
import Reset from '../reset-pw/reset-password';
import MerchantDetail from '../../features/merchant-detail/merchant-detail';
import { MyRewards } from '../../features/my-rewards/my-rewards';
import { RedeemOffer } from '../../features/redeem-offer/redeem-offer';



const MainContent = () => {
    return (
        <div className='main-content'>
            <Routes>
                <Route path="/" element={<EventMain />} />
                <Route path="/events" element={<EventMain />} />
                <Route path="/merchants" element={<MerchantCatalogList />} />
                <Route path="/offers" element={<OffersList />} />
                <Route path="/merchants/:catalog" element={<MerchantList />} />
                <Route path="/merchant-detail/:merchantId" element={<MerchantDetail />} />
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/reset" element={<Reset/>}/>
                <Route path="/my-rewards" element={<MyRewards/>}/>
                <Route path="/redeem-offer/:merchantId" element={<RedeemOffer/>}/>

            </Routes>
        </div>

    )
}

export default MainContent;

