import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { OfferItem } from "../../types/offer-item";
import "./offers-list.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import { fetchAllOffers, selectOffers, selectOffersState } from "./offers-list.slice";
import { useEffect } from "react";
import { urlFor } from "../../client/sanity-client";
import { LoadingIndicator } from "../../components/loader/loader";

export const OffersList = () => {
  const offers = useAppSelector(selectOffers);
  const dispatch = useAppDispatch();
  const offersState = useAppSelector(selectOffersState);
  useEffect(() => {
    dispatch(fetchAllOffers());
  }, [dispatch]);

  return (
    <div className="offers-container">
      <div className="offers-all-title">Offers</div>
      {/* {
        offersState == 'loading' && <LoadingIndicator/>
     } */}
      {/* <div className="offers-banner" ><img src="https://rightpropertygroup.com.au/wp-content/uploads/2016/09/Offers-multiply.jpg"/></div> */}
      {
        // offersState == 'idle' &&  
        <div className="offers-list">
          {offers &&
            offers.map((o: OfferItem) => (
              <div className="offer-item">
                <Link className="merchant-item" state={{ from: 'offers' }} to={`/merchant-detail/${o.merchantId}`}>
                  <img src={urlFor(o.image!).url()} />
                  <span>{o.title}</span>
                  <span className="offers-merchant-item-merchant-name">{o.merchantName}</span>
                </Link>
              </div>
            ))}
        </div>
      }
    </div>
  );
};
