import { useParams, useNavigate, Link } from "react-router-dom";
import "./merchant-list.scss";
import { OfferItem } from "../../types/offer-item";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchMerchantsByCatalog,
  selectMerchantsByCatalog,
} from "../merchant/merchant.slice";
import { MerchantItem } from "../../types/merchant-item";
import { urlFor } from "../../client/sanity-client";

export const MerchantList = () => {
  const navigate = useNavigate();
  const { catalog } = useParams();
  const dispatch = useAppDispatch();
  const merchants = useAppSelector(selectMerchantsByCatalog);

  useEffect(() => {
    dispatch(fetchMerchantsByCatalog(catalog?.toString() ?? ""));
  }, [dispatch]);

  console.log(merchants);
  const handleGoBack = () => {
    navigate("/merchants");
  };

  return (
    <div className="merchant-list-container">
      <button onClick={handleGoBack} className="back">
        Back
      </button>
      <div className="merchant-list">
        <div className="merchant-list-title"> {catalog} Merchants</div>
        {merchants && (
          <div className="merchant-list-content">
            {merchants.map((m: MerchantItem) => {
              return (
                <Link className="merchant-item" state={{ from: 'merchants' }} to={`/merchant-detail/${m._id}`}>

                  <img src={urlFor(m.image!).url()} width={300} height={200}></img>
                  <h3>{m.merchantName}</h3>
                  <div>{m.profile}</div>

                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
