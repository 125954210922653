export const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(224, 212, 212, 0.338)'
  },
};

export const customModalStylesFullScreen = {
  content: {
    top: '10%',
    left: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-10%',
    transform: 'translate(-10%, -10%)',
  },
  overlay: {
    backgroundColor: 'rgba(224, 212, 212, 0.338)'
  },
};