import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store'
import { EventItem } from '../../types/event-item';
import { client } from '../../client/sanity-client';
import { fetchOffersByMerchant, merchantByIdQuery, merchantsByCatalogQuery } from '../../client/query';
import { MerchantItem } from '../../types/merchant-item';
import { OfferItem } from '../../types/offer-item';

export interface MerchantsDetailState {
    merchant: MerchantItem;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: MerchantsDetailState = {
    merchant: {
        _id:'',
        merchantName:'',
        address:'',
        catalog:'',
        contact:'',
        profile:'',
        image:null,
        offers:[],
        userId:'',
        approved:false
    },
    status: 'idle',
};

export const fetchMerchantById = createAsyncThunk(
    'merchant/fetchMerchantById',
    async (merchantId:string) => {
        try {
            const response = await client.fetch(merchantByIdQuery(merchantId));
            // The value we return becomes the `fulfilled` action payload
            const merchant = response[0];
            const offers = await client.fetch(fetchOffersByMerchant(merchantId));
            merchant.offers = offers;
            return merchant;
        } catch (error) {
            alert(error)
        }
    }
);

export const merchantDetailSlice = createSlice({
    name: 'merchant-detail',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<OfferItem>) => {
        //   state.merchant.offers += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchMerchantById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMerchantById.fulfilled, (state, action) => {
                state.status = 'idle';
                state.merchant = action.payload;
            })
            .addCase(fetchMerchantById.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

// export const { fetchEvents } = eventSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMerchantById= (state: RootState) => state.merchantDetail.merchant;
export const selectMerchantByIdState= (state: RootState) => state.merchantDetail.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default merchantDetailSlice.reducer;
