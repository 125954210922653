import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword , GoogleAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "./sign-in.scss";
import { signInWithGoogle } from "../../firebase";

import googleIcon from '../../img/google.png';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user && user.uid.length>0) navigate("/");
  }, [user, loading]);
  return (
    <div className="login">
      <h3>Join UBC community</h3>
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={async() =>{
           try {
            const res = await signInWithEmailAndPassword(auth,email, password);
            if(!res.user)
            {
              alert('Login Failed')
            }
           } catch (error) {
            alert(error)
           }
          } }
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          <img src={googleIcon} className="google-icon"/> Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Login;